import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const DEFAULT_LAT = 38.9377754;
const DEFAULT_LON = -77.0330355;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

const StationMap = ({ evStations, onCenterChanged, connectorNameMap, searchOptions }) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markers = useRef(new Map()); // Changed to Map to track markers by station ID
    const lines = useRef(new Map()); // Changed to Map to track lines by ID
    const addedStationIds = useRef(new Set()); // Track which stations have been added
    const geocoder = useRef(null);

    useEffect(() => {
        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [DEFAULT_LON, DEFAULT_LAT],
            zoom: 9,
            minZoom: 4
        });

        // Add geocoder control
        geocoder.current = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false,
            placeholder: 'Search for a location',
            proximity: {
                longitude: DEFAULT_LON,
                latitude: DEFAULT_LAT
            }
        });

        map.current.addControl(geocoder.current);

        // Move geocoder outside of map
        const geocoderContainer = document.querySelector('.mapboxgl-ctrl-geocoder');
        const headerControls = document.querySelector('.controls');
        if (geocoderContainer && headerControls) {
            geocoderContainer.style.margin = '0';
            headerControls.insertBefore(geocoderContainer, headerControls.firstChild);
        }

        // Handle geocoder result
        geocoder.current.on('result', (e) => {
            const coords = e.result.center;
            map.current.flyTo({
                center: coords,
                zoom: 13
            });
        });

        map.current.on('moveend', () => {
            const center = map.current.getCenter();
            const bounds = map.current.getBounds();

            // Create points for the west and east boundaries
            const northPoint = turf.point([bounds.getNorth(), bounds.getCenter().lat]);
            const southPoint = turf.point([bounds.getSouth(), bounds.getCenter().lat]);
            const westPoint = turf.point([bounds.getWest(), bounds.getCenter().lat]);
            const eastPoint = turf.point([bounds.getEast(), bounds.getCenter().lat]);

            const heightKm = turf.distance(northPoint, southPoint);
            const widthKm = turf.distance(westPoint, eastPoint);
            const radiusKm = Math.sqrt(Math.pow(heightKm, 2) + Math.pow(widthKm, 2)) / 2;
            const radiusMiles = radiusKm * 0.621371;

            onCenterChanged({ lat: center.lat, lon: center.lng, radius: radiusMiles });
        });

        console.log('map initialized');
    }, []);

    // Clear all markers when search options change
    useEffect(() => {
        if (!map.current) return;

        // Clear all existing markers and lines
        markers.current.forEach(marker => marker.remove());
        markers.current.clear();
        
        lines.current.forEach((line, lineId) => {
            if (map.current.getLayer(lineId)) {
                map.current.removeLayer(lineId);
                map.current.removeSource(lineId);
            }
        });
        lines.current.clear();
        
        // Reset the set of added station IDs
        addedStationIds.current.clear();

    }, [searchOptions]); // Only clear when search options change

    useEffect(() => {
        if (!map.current) return;

        function addToggleListener() {
            const toggle = document.querySelector('.pinball-machines-toggle');
            const arrow = toggle.querySelector('.arrow-icon');
            const machineList = document.querySelector('.pinball-machine-names-list');
        
            toggle.addEventListener('click', () => {
                const isExpanded = machineList.style.display === 'block';
                machineList.style.display = isExpanded ? 'none' : 'block';
                arrow.classList.toggle('down', !isExpanded);
            });
        }

        // Add new markers only for stations we haven't seen yet
        evStations.forEach(charger => {
            // Skip if we've already added this station
            if (addedStationIds.current.has(charger.id)) {
                return;
            }

            // Mark this station as added
            addedStationIds.current.add(charger.id);

            const chargerPopup = new mapboxgl.Popup({ offset: 25 })
                .setHTML(
                    `<h1><a href="https://afdc.energy.gov/stations/#/station/${charger.id}" target="_blank">
                    ${charger.station_name}
                    </a></h1>
                    <a href="https://www.google.com/maps?q=${encodeURIComponent(charger.street_address + ', ' + charger.city + ', ' + charger.state)}" target="_blank">
                    <p>${charger.street_address}</p>
                     <p>${charger.city}, ${charger.state}</p></a>
                     <ul class="charger-connector-list">
                        ${charger.ev_connector_types.map(type => `<li class="charger-connector">${connectorNameMap[type]}</li>`).join('')}
                    </ul>
                     <p class="pinball-machines-toggle"><span class="arrow-icon"></span>${charger.total_pinball_machines} machines</p>
                     <ul class="pinball-machine-names-list" style="display: none;">
                         ${charger.nearby_pinball_machines.map(name => `<li class="pinball-machine-name">${name}</li>`).join('')}
                     </ul>`
                );

            chargerPopup.on('open', addToggleListener);

            const evStationCoords = [charger.longitude, charger.latitude];

            // Add markers for nearby pinball locations
            charger.nearby_pinball_locations.forEach(pinballLocation => {
                const pinballLocationId = `pinball-${pinballLocation.id}`;
                if (markers.current.has(pinballLocationId)) {
                    return;
                }

                const pinballLocationPopup = new mapboxgl.Popup({ offset: 25 })
                    .setHTML(
                        `<h1><a href="https://pinballmap.com/map/?by_location_id=${pinballLocation.id}" target="_blank">
                        ${pinballLocation.name}
                        </a></h1>
                        <a href="https://www.google.com/maps?q=${encodeURIComponent(pinballLocation.name + ', ' + pinballLocation.street + ', ' + pinballLocation.city + ', ' + pinballLocation.state)}" target="_blank">
                         <p>${pinballLocation.street}</p>
                         <p>${pinballLocation.city}, ${pinballLocation.state}</p>
                         </a>
                         <p class="pinball-machines-toggle"><span class="arrow-icon"></span>${pinballLocation.num_machines} machines</p>
                         <ul class="pinball-machine-names-list" style="display: none;">
                             ${pinballLocation.machine_names.map(name => `<li class="pinball-machine-name">${name}</li>`).join('')}
                         </ul>`
                    );
                
                pinballLocationPopup.on('open', addToggleListener);

                const pinballMarker = new mapboxgl.Marker({
                    element: document.createElement('div')
                })
                    .setLngLat([pinballLocation.lon, pinballLocation.lat])
                    .setPopup(pinballLocationPopup)
                    .addTo(map.current);

                pinballMarker.getElement().innerHTML = `<div class="pinball-marker map-marker">🪩${pinballLocation.num_machines}🪩</div>`;

                pinballMarker.getElement().addEventListener('click', () => {
                    if (map.current.getZoom() < 12) {
                        map.current.flyTo({
                            center: [pinballLocation.longitude, pinballLocation.latitude],
                            essential: true,
                            zoom: 15
                        });
                    }
                });

                markers.current.set(pinballLocationId, pinballMarker);

                const pinballCoords = [pinballLocation.lon, pinballLocation.lat];
                const lineId = `line-${charger.id}-${pinballLocation.id}`;

                if (!lines.current.has(lineId)) {
                    const line = {
                        'id': lineId,
                        'type': 'line',
                        'source': {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'properties': {},
                                'geometry': {
                                    'type': 'LineString',
                                    'coordinates': [evStationCoords, pinballCoords]
                                }
                            }
                        },
                        'paint': {
                            'line-color': '#888',
                            'line-width': 3
                        }
                    };

                    map.current.addLayer(line);
                    lines.current.set(lineId, line);
                }
            });

            const chargerId = `charger-${charger.id}`;
            const chargerMarker = new mapboxgl.Marker({
                element: document.createElement('div')
            })
                .setLngLat([charger.longitude, charger.latitude])
                .setPopup(chargerPopup)
                .addTo(map.current);

            chargerMarker.getElement().innerHTML = `<div class="charger-marker map-marker">⚡️${charger.total_pinball_machines}⚡️</div>`;
            
            chargerMarker.getElement().addEventListener('click', () => {
                if (map.current.getZoom() < 12) {
                    map.current.flyTo({
                        center: [charger.longitude, charger.latitude],
                        essential: true,
                        zoom: 15
                    });
                }
            });

            markers.current.set(chargerId, chargerMarker);
        });

        console.log('map updated with new stations');

    }, [evStations, searchOptions]);

    return <div className="map" ref={mapContainer} />;
};

export default StationMap;
